import { getColumns } from './DictionaryFieldBuilder.Grid';
import AssignmentBuilder from '../../Functions/Assignment/AssignmentBuilder';
import DeclarationBuilderProps from '../../Functions/Declaration/DeclarationBuilderProps';
import ErrorList from '../../Common/ErrorList/ErrorList';
import guidance from '../ReportConfigEditor/ReportConfigEditor.Guidance';
import InfoIcon from '../../Common/InfoIcon/InfoIcon';
import locationType from '../../../types/functions/Location/LocationType';
import pseudocodeHelper from '../../../infrastructure/helpers/functions/common/pseudocodeHelper';
import React from 'react';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import DictionaryField from '../../../types/report/DictionaryField';
import Assignment from '../../../types/functions/Assignment/Assignment';
import Dictionary from '../../../types/report/Dictionary';

class DictionaryFieldBuilder extends React.Component<DeclarationBuilderProps<DictionaryField>> {

    constructor(props: DeclarationBuilderProps<DictionaryField>) {
        super(props);

        this.onResetAssignmentClick = this.onResetAssignmentClick.bind(this);
        this.getFields = this.getFields.bind(this);
        this.getField = this.getField.bind(this);
        this.getAssignmentContent = this.getAssignmentContent.bind(this);
        this.onReorderConditions = this.onReorderConditions.bind(this);
        this.onAddFieldClick = this.onAddFieldClick.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onDataTypeChange = this.onDataTypeChange.bind(this);
        this.onBusinessDescriptionChange = this.onBusinessDescriptionChange.bind(this);
        this.onRemoveFieldClick = this.onRemoveFieldClick.bind(this);
    }

    onResetAssignmentClick(location: ReportLogicLocation): void {

        this.props.onResetAssignmentClick(location, true);
    }

    getFields(): DictionaryField[] {

        let dict = this.getDictionary();

        return dict.fields;
    }

    getField(location: ReportLogicLocation): DictionaryField {

        let dict = this.getDictionary();

        let field = dict.fields.find(f => f.number === location.fieldId) as DictionaryField;

        return field;
    }

    getDictionary(): Dictionary {

        return this.props.reportConfig.dictionaries.find(d => d.number === this.props.declarationBatchNumber) as Dictionary;
    }

    getLocation(fieldId: number): ReportLogicLocation {

        let location = {} as ReportLogicLocation;

        location.statement = locationType.statement.dictionaryField;
        location.statementNumber = this.props.declarationBatchNumber as number;
        location.fieldId = fieldId;

        return location;
    }

    onReorderConditions(field: DictionaryField, error?: string) {

        if (error && !field.errors.includes(error)) {
            field.errors.push(error);
        }

        let location = this.getLocation(field.number);
        this.props.onDeclarationChange(location, field);
    }

    getAssignmentContent(definition: DictionaryField, isReadOnly: boolean): JSX.Element {

        let location = this.getLocation(definition.number);

        let field = this.getField(location);

        return (
            <>
                <AssignmentBuilder
                    isReadOnly={isReadOnly}
                    location={location}
                    assignment={field.assignment}
                    fieldName={pseudocodeHelper.wrapInBrackets(definition.name)}
                    functions={this.props.reportConfig.userDefinedFunctions}
                    lookups={this.props.reportConfig.lookups}
                    dictionaries={this.props.reportConfig.dictionaries}
                    variables={this.props.reportConfig.variables}
                    dataSources={this.props.collections.dataSources}
                    reportFields={[]}
                    customDataSourceFields={null as any}
                    onAddAssignmentClick={this.props.onAddAssignmentClick}
                    onRemoveAssignmentClick={this.props.onRemoveAssignmentClick}
                    onResetAssignmentClick={this.onResetAssignmentClick}
                    onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                    onParenthesesChange={this.props.onParenthesesChange}
                    onAddConditionClick={this.props.onAddConditionClick}
                    onRemoveConditionClick={this.props.onRemoveConditionClick}
                    onComparisonTypeChange={this.props.onComparisonTypeChange}
                    onOperandClick={this.props.onOperandClick}
                    onCopyClick={this.props.onCopyClick}
                    onPasteClick={this.props.onPasteClick}
                    onValidateFieldClick={this.props.onValidateDeclarationClick}
                    allowPaste={this.props.allowPaste}
                    onReorderAssignmentConditions={(error) => { this.onReorderConditions(field, error); }} />

                <ErrorList errors={field.errors} className="mt-2" />
            </>
        );
    }

    onAddFieldClick() : void {

        this.props.onAddDeclarationClick(this.setFieldName);
    }

    onRemoveFieldClick(number: number): void {

        let location = this.getLocation(number);

        //const validationError = await reportComponentBuildersHelper.validateAction(
        //    number,
        //    false,
        //    this.props.reportConfig.variables as IdNameAndNumber[],
        //    ComponentType.Variable,
        //    this.props.reportConfig.id || 0,
        //    ActionType.Remove);
        //if (!validationError) {
            this.props.onRemoveDeclarationClick(location, (dictionaries) => {

                //let updated = reorderHelper.rearangeElementsWhenRemoveOne(dictionaries) as ReportVariable[];

                //reorderHelper.changeReferences(
                //    this.props.reportConfig,
                //    updated,
                //    (operand: Operand, oldAndNewNumbers: Map<number, number>) => {
                //        if (operand.variableNumber && oldAndNewNumbers.has(operand.variableNumber)) {
                //            operand.variableNumber = oldAndNewNumbers.get(operand.variableNumber) as number;
                //        }
                //    }
                //);
            });
        //}
        //else {
        //    this.props.onRemoveDeclarationClick(location, undefined, undefined, validationError);
        //}
    }

    setFieldName(field: DictionaryField): void {

        field.name = `field-${field.number}`;
    }

    onNameChange(name: string, number: number): void {

        let location = this.getLocation(number);

        this.props.onDeclarationChange(location, { name: name } as DictionaryField);
    }

    onBusinessDescriptionChange(businessDescription: string, number: number): void {

        let location = this.getLocation(number);

        this.props.onDeclarationChange(location, { businessDescription: businessDescription } as DictionaryField);
    }

    onDataTypeChange(dataType: string, number: number, assignment: Assignment): void {

        let location = this.getLocation(number);
        let changes = {} as DictionaryField;

        let proceed = window.confirm('Changing the data type will reset the logic for this field.');
        if (!proceed) {
            return;
        }

        let error = this.props.validateRemoveDeclaration(location);
        if (error) {
            changes.errors = [error];
        }
        else {
            changes.dataType = dataType;
        }

        this.props.onDeclarationChange(location, changes);
    }

    render(): JSX.Element {

        return (
            <>
                {
                    typeHelper.isNumber(this.props.declarationBatchNumber) &&
                    <>
                        <div className="section-heading">
                            <InfoIcon info={guidance.dictionaryField} />
                            <h4>Fields</h4>
                        </div>

                        <ReportLogicList
                            isWaiting={this.props.isWaiting}
                            items={this.getFields()}
                            columns={getColumns(
                                this.onNameChange,
                                this.onDataTypeChange,
                                this.onBusinessDescriptionChange,
                                this.onRemoveFieldClick,
                                this.props.isReadOnly
                            )}
                            getContent={x => this.getAssignmentContent(x, this.props.isReadOnly)}
                            getKey={x => x.number}
                            createHtmlId={x => reportConfigNavigation.buildDictionaryFieldId(x.number)} />

                        <div className="row no-gutters mb-3">
                            <div className="col-auto">
                                <button
                                    onClick={this.onAddFieldClick}
                                    className="btn btn-light">+ Field</button>
                            </div>
                        </div>
                    </>
                }
            </>
        );
    }
}

export default DictionaryFieldBuilder;
