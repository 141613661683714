import { doNotPopulateOption, operandTypeOptions, includeDoNotPopulate, firdsResultSetOption, arrayItemOption } from './OperandBuilder.Helpers';
import { firdsResultSetTypes } from '../../../infrastructure/constants/functions/specialOperandTypes';
import BaseOperandProps from './Props/BaseOperandProps';
import ConstantBuilder from './Constant/ConstantBuilder';
import constantInitialValueHelper from '../../../infrastructure/helpers/functions/operand/constant/constantInitialValueHelper';
import CustomDataSourceFieldBuilder from './CustomDataSourceField/CustomDataSourceFieldBuilder';
import CustomDataSourceLegitimacy from '../../../infrastructure/types/Functions/CustomDataSourceLegitimacy';
import customDataSourceType from '../../../infrastructure/constants/functions/customDataSourceType';
import dataType from '../../../infrastructure/constants/dataType';
import FieldOperandProps from './Props/FieldOperandProps';
import FieldValueBuilder from './Field/FieldValueBuilder';
import FirdsResultSetBuilder from './FirdsResultSet/FirdsResultSetBuilder';
import FunctionBuilder from './Function/FunctionBuilder';
import LeiResultSetBuilder from './LeiResultSet/LeiResultSetBuilder';
import MultiResultLookupBuilder from './MultiResultLookup/MultiResultLookupBuilder';
import Operand from '../../../types/functions/Operand/Operand';
import operandHelper from '../../../infrastructure/helpers/functions/operand/operandHelper';
import operandRuleHelper from '../../../infrastructure/helpers/functions/operand/operandRuleHelper';
import operandType from '../../../infrastructure/constants/functions/operandType';
import RadioButtonGroup from '../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';
import SelectOption from '../../../infrastructure/types/SelectOption';
import SpecialScenario from './Props/SpecialScenario';
import SpecialValueBuilder from './Constant/SpecialValue/SpecialValueBuilder';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import Selector from '../../../types/report/Selector';
import IsinsSetBuilder from './FirdsResultSet/IsinsSetBuilder';
import LeiSetBuilder from './LeiResultSet/LeiSetBuilder';
import pseudoFunctionHelper from '../../../infrastructure/helpers/functions/function/pseudoFunctionHelper';
import pseudoFunction from '../../../infrastructure/constants/functions/pseudoFunction';
import operandSpecialValueType from '../../../infrastructure/constants/functions/operandSpecialValueType';
import ExchangeRatesResultSetBuilder from './ExchangeRatesResultSet/ExchangeRatesResultSetBuilder';
import ExchangeRatesSetBuilder from './ExchangeRatesResultSet/ExchangeRatesSetBuilder';
import ArrayItemLegitimacy from '../../../infrastructure/types/Functions/ArrayItemLegitimacy';
import ParameterBuilder from './Parameter/ParameterBuilder';
import UserDefinedFunctionParameter from '../../../types/report/UserDefinedFunctionParameter';
import UserDefinedFunction from '../../../types/report/UserDefinedFunction';

type OperandBuilderProps =
    BaseOperandProps &
    FieldOperandProps &
    {
        customDataSourceLegitimacy: CustomDataSourceLegitimacy;
        specialScenario: SpecialScenario;
        customDataSourceContext: string | null;
        isinSelectors: Selector[];
        leiSelectors: Selector[];
        exchangeRatesSelectors: Selector[];
        arrayItemLegitimacy: ArrayItemLegitimacy;
        userDefinedFunctions: UserDefinedFunction[];
        functionParameters: UserDefinedFunctionParameter[] | null;
    };

class OperandBuilder extends React.Component<OperandBuilderProps> {
    get isDateTimeFxOrNotSpecialValueType(): boolean {
        return !typeHelper.isString(this.props.operandRules.specialValueType) ||
            this.props.operandRules.specialValueType === operandSpecialValueType.publicHolidayCurrency || 
            this.props.operandRules.specialValueType === operandSpecialValueType.publicHolidayCountry;
    }

    private firdsPseudoFunctionInitialized: boolean;
    private firdsPseudoFunction: Operand;

    constructor(props: OperandBuilderProps) {
        super(props);

        this.onOperandTypeChange = this.onOperandTypeChange.bind(this);
        this.includeOption = this.includeOption.bind(this);
        this.getOperandTypeValue = this.getOperandTypeValue.bind(this);
        this.setConstantDefaults = this.setConstantDefaults.bind(this);
        this.initializeIsinSet = this.initializeIsinSet.bind(this);

        this.firdsPseudoFunctionInitialized = false;
        this.firdsPseudoFunction = operandHelper.getEmpty();
    }

    onIsinSelectorChange(value: string): void {

        if (!this.firdsPseudoFunctionInitialized) {
            this.firdsPseudoFunction = pseudoFunctionHelper.buildSpecial(operandType.firdsResultSetEsma, +value);
            this.firdsPseudoFunctionInitialized = true;
        }

        (this.firdsPseudoFunction.arguments as Operand[])[0].selectorNumber = +value;

        this.props.onOperandChange(this.firdsPseudoFunction, { doNotPopulate: false, pseudoFunction: pseudoFunction.firds });
    }

    onLeiSelectorChange(value: string): void {

        let changes = pseudoFunctionHelper.buildSpecial(operandType.leiResultSetLevel1, +value);

        this.props.onOperandChange(changes, { doNotPopulate: false, pseudoFunction: pseudoFunction.lei });
    }

    onOperandTypeChange(value: string) {

        if (value === doNotPopulateOption.value) {

            this.props.onOperandChange({} as Operand, { doNotPopulate: true, pseudoFunction: null });
            return;
        }

        let operand = operandHelper.getEmpty();

        if (value === arrayItemOption.value) {

            operand.dataType = this.props.arrayItemLegitimacy.itemDataType as string;
        }

        operand.operandType = value === firdsResultSetOption.value ? operandType.firdsResultSetEsma : value;

        this.setConstantDefaults(value, operand);

        this.props.onOperandChange(operand);
    }

    includeOption(option: SelectOption): boolean {

        switch (option.value) {
            case operandType.group:
            case operandType.lookupResultSet:
            case operandType.leiResultSetLevel1:
            case operandType.exchangeRatesResultSet:
                return operandRuleHelper.isSpecialOperandTypeAllowed(this.props.operandRules, option.value);

            case firdsResultSetOption.value:
                return firdsResultSetTypes.some(type => operandRuleHelper.isSpecialOperandTypeAllowed(this.props.operandRules, type));

            case operandType.firdsField:
                return this.props.customDataSourceContext === customDataSourceType.firds;

            case operandType.leiLevel1Field:
                return this.props.customDataSourceContext === customDataSourceType.leiLevel1;

            case operandType.exchangeRatesField:
                return this.props.customDataSourceContext === customDataSourceType.exchangeRates;

            case operandType.field:
                return !typeHelper.isArray(this.props.operandRules.specialOperandTypes) && !typeHelper.isString(this.props.customDataSourceContext) && !typeHelper.isArray(this.props.functionParameters);

            case operandType.constant:
            case operandType.function:
                return !typeHelper.isArray(this.props.operandRules.specialOperandTypes);

            case doNotPopulateOption.value:
                return includeDoNotPopulate(this.props.location);

            case operandType.arrayItem:
                return this.props.arrayItemLegitimacy.isAllowed ?? false;

            case operandType.parameter:
                return typeHelper.isArray(this.props.functionParameters);

            case operandType.userDefinedFunction:
                return typeHelper.isArray(this.props.userDefinedFunctions) && this.props.userDefinedFunctions?.length > 0;

            default:
                throw new Error(`Unexpected operand type option '${option.value}'`);
        }
    }

    initializeIsinSet(): boolean {
        this.firdsPseudoFunctionInitialized = false
        var selectedIsinNumber = 1;
        if (this.props.operand.selectorNumber !== null && this.props.operand.selectorNumber !== undefined) {
            selectedIsinNumber = this.props.operand.selectorNumber;
        }
        else if (this.props.isinSelectors.length === 0) {
            selectedIsinNumber = 0;
        }
        else if (this.props.isinSelectors.length === 1) {
            selectedIsinNumber = this.props.isinSelectors[0].number || 1;
        }

        this.firdsPseudoFunction = pseudoFunctionHelper.buildSpecial(operandType.firdsResultSetEsma, selectedIsinNumber);
        return true;
    }

    initializeExchangeRatesSet(): boolean {
        if (this.props.operand.selectorNumber === null || this.props.operand.selectorNumber === undefined) {

            this.props.operand.selectorNumber = 1;
        }

        return true;
    }

    getOperandTypeValue(): string {

        if (this.props.specialScenario.doNotPopulate) {

            return doNotPopulateOption.value;
        }

        if (firdsResultSetTypes.includes(this.props.operand.operandType)) {

            return firdsResultSetOption.value;
        }

        if (this.isFieldType(this.props.operand.operandType)) {

            return operandType.field;
        }

        if (this.props.operand.operandType === operandType.userDefinedFunction) {

            return operandType.function;
        }

        return this.props.operand.operandType;
    }

    setConstantDefaults(type: string, operand: Operand): void {

        if (type !== operandType.constant) {
            return;
        }

        operand.dataType = typeHelper.isArray(this.props.operandRules.allowedTypes) ? (this.props.operandRules.allowedTypes as string[])[0] : dataType.string;

        operand.value = constantInitialValueHelper.get(operand.dataType);
    }

    isFieldType(type: string) {
        return (
            type === operandType.field ||
            type === operandType.lookupField ||
            type === operandType.reportField ||
            type === operandType.variable ||
            type === operandType.dictionary
        );
    }

    render(): JSX.Element {
        return (
            <>
                {
                    this.isDateTimeFxOrNotSpecialValueType &&
                    <div className="mb-3">
                        <RadioButtonGroup
                            options={operandTypeOptions.filter(this.includeOption)}
                            name="operand-types"
                            value={this.getOperandTypeValue()}
                            onChange={this.onOperandTypeChange} />
                    </div>
                }
                {
                    <>
                        {
                            typeHelper.isString(this.props.operandRules.specialValueType) &&
                            this.props.operand.operandType === operandType.constant &&
                            <SpecialValueBuilder
                                collections={this.props.collections}
                                operandRules={this.props.operandRules}
                                operand={this.props.operand}
                                onOperandChange={this.props.onOperandChange} />
                        }
                        {
                            !this.props.specialScenario.doNotPopulate &&
                            this.isFieldType(this.props.operand.operandType) &&
                            <FieldValueBuilder
                                collections={this.props.collections}
                                operandRules={this.props.operandRules}
                                dictionaries={this.props.dictionaries}
                                lookups={this.props.lookups}
                                variables={this.props.variables}
                                dataSource1Id={this.props.dataSource1Id}
                                dataSource2Id={this.props.dataSource2Id}
                                location={this.props.location}
                                operand={this.props.operand}
                                onOperandChange={this.props.onOperandChange} />
                        }
                        {
                            !this.props.specialScenario.doNotPopulate &&
                            (this.props.operand.operandType === operandType.function ||
                                this.props.operand.operandType === operandType.userDefinedFunction) &&
                            <FunctionBuilder
                                operandRules={this.props.operandRules}
                                operand={this.props.operand}
                                onOperandChange={this.props.onOperandChange}
                                customDataSourceLegitimacy={this.props.customDataSourceLegitimacy}
                                pseudoFunction={this.props.specialScenario.pseudoFunction}
                                isinSelectors={this.props.isinSelectors}
                                leiSelectors={this.props.leiSelectors}
                                exchangeRatesSelectors={this.props.exchangeRatesSelectors}
                                userDefinedFunctions={this.props.userDefinedFunctions}
                                location={this.props.location} />
                        }
                        {
                            !this.props.specialScenario.doNotPopulate &&
                            !typeHelper.isString(this.props.operandRules.specialValueType) &&
                            this.props.operand.operandType === operandType.constant &&
                            <ConstantBuilder
                                operandRules={this.props.operandRules}
                                operand={this.props.operand}
                                onOperandChange={this.props.onOperandChange} />
                        }
                        {
                            !this.props.specialScenario.doNotPopulate &&
                            this.props.operand.operandType === operandType.lookupResultSet &&
                            <MultiResultLookupBuilder
                                collections={this.props.collections}
                                operandRules={this.props.operandRules}
                                dictionaries={this.props.dictionaries}
                                lookups={this.props.lookups}
                                variables={this.props.variables}
                                dataSource1Id={this.props.dataSource1Id}
                                dataSource2Id={this.props.dataSource2Id}
                                location={this.props.location}
                                operand={this.props.operand}
                                onOperandChange={this.props.onOperandChange} />
                        }
                        {
                            !this.props.specialScenario.doNotPopulate &&
                            firdsResultSetTypes.includes(this.props.operand.operandType) &&
                            <FirdsResultSetBuilder
                                value={this.props.operand.operandType}
                                onChange={value => this.props.onOperandChange({ operandType: value } as Operand)} />
                        }
                        {
                            firdsResultSetTypes.includes(this.props.operand.operandType) &&
                            this.initializeIsinSet() &&
                            <IsinsSetBuilder
                                options={this.props.isinSelectors}
                                value={(this.props.operand.selectorNumber)}
                                onChange={value => this.props.onOperandChange({ selectorNumber: +value } as Operand)} />
                        }
                        {
                            !this.props.specialScenario.doNotPopulate &&
                            this.props.operand.operandType === operandType.leiResultSetLevel1 &&
                            <LeiResultSetBuilder value={this.props.operand.operandType} />
                        }
                        {
                            this.props.operand.operandType === operandType.leiResultSetLevel1 &&
                            <LeiSetBuilder
                                options={this.props.leiSelectors}
                                value={(this.props.operand.selectorNumber)}
                                onChange={value => this.props.onOperandChange({ selectorNumber: +value } as Operand)} />
                        }
                        {
                            !this.props.specialScenario.doNotPopulate &&
                            this.props.operand.operandType === operandType.exchangeRatesResultSet &&
                            <ExchangeRatesResultSetBuilder value={this.props.operand.operandType} />
                        }
                        {
                            this.props.operand.operandType === operandType.exchangeRatesResultSet &&
                            this.initializeExchangeRatesSet() &&
                            <ExchangeRatesSetBuilder
                                options={this.props.exchangeRatesSelectors}
                                value={(this.props.operand.selectorNumber)}
                                onChange={value => this.props.onOperandChange({ selectorNumber: +value } as Operand)} />
                        }
                        {
                            !this.props.specialScenario.doNotPopulate &&
                            this.props.operand.operandType === operandType.firdsField &&
                            <CustomDataSourceFieldBuilder
                                fields={this.props.collections.customDataSourceFields.firds}
                                prefix="FIRDS"
                                operandRules={this.props.operandRules}
                                operand={this.props.operand}
                                onOperandChange={this.props.onOperandChange} />
                        }
                        {
                            !this.props.specialScenario.doNotPopulate &&
                            this.props.operand.operandType === operandType.leiLevel1Field &&
                            <CustomDataSourceFieldBuilder
                                fields={this.props.collections.customDataSourceFields.leiLevel1}
                                prefix="LEI"
                                operandRules={this.props.operandRules}
                                operand={this.props.operand}
                                onOperandChange={this.props.onOperandChange} />
                        }
                        {
                            !this.props.specialScenario.doNotPopulate &&
                            this.props.operand.operandType === operandType.exchangeRatesField &&
                            <CustomDataSourceFieldBuilder
                                fields={this.props.collections.customDataSourceFields.exchangeRates}
                                prefix="EXCHANGE-RATES"
                                operandRules={this.props.operandRules}
                                operand={this.props.operand}
                                onOperandChange={this.props.onOperandChange} />
                        }
                        {
                            !this.props.specialScenario.doNotPopulate &&
                            this.props.operand.operandType === operandType.parameter &&
                            <ParameterBuilder
                                parameters={this.props.functionParameters as UserDefinedFunctionParameter[]}
                                operandRules={this.props.operandRules}
                                operand={this.props.operand}
                                onOperandChange={this.props.onOperandChange} />
                        }
                    </>
                }
            </>
        );
    }
}

export default OperandBuilder;
