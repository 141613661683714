import './LinkWrapper.scss';
import { NavLink } from 'react-router-dom';
import React from 'react';
import history from './../infrastructure/helpers/react/history';
import stringHelper from '../infrastructure/helpers/common/stringHelper';

interface Props {
    name: string;
    links: [string, string][];
    onClick: (e: React.MouseEvent<HTMLElement>, route: string) => void;
}

interface State {
    isExpanded: boolean;
}

class LinkWrapper extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.isActive = this.isActive.bind(this);
        this.buildItem = this.buildItem.bind(this);

        this.state = { isExpanded: this.props.links.some(link => this.isActive(link[0])) };
    }

    isActive(link: string): boolean {
        return stringHelper.isEmpty(history.location.search) ? false : link.includes(history.location.search);
    }

    buildItem(link: [string, string], i: number): JSX.Element {
        return (
            <li key={i}>
                <NavLink exact activeClassName="active-sidebar-option" isActive={() => this.isActive(link[0])} to={link[0]} onClick={(e: React.MouseEvent<HTMLElement>) => { this.props.onClick(e, link[0]) }}>{link[1]}</NavLink>
            </li>
        );
    }

    render(): JSX.Element {
        return (
            <div className="link-wrapper">
                <button onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}>{this.props.name}</button>
                {
                    this.state.isExpanded &&
                    <ul>
                        {this.props.links.map(this.buildItem)}
                    </ul>
                }
            </div>
        );
    }
}

export default LinkWrapper;
