import { AxiosResponse } from 'axios';
import { getDataSourceOptions, getEmptyReportConfig, getRegimeDetails } from './ReportPage/ReportPage.Helpers';
import { reportType } from '../../../infrastructure/constants/reportType';
import actions from '../../../store/actions';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import caseHelper from '../../../infrastructure/helpers/functions/caseHelper';
import clear from '../../../infrastructure/helpers/common/array/clear';
import copyObject from '../../../infrastructure/helpers/common/copyObject';
import DataSourceForm from '../DataSources/DataSourceForm';
import enrichmentHelper from '../../../infrastructure/helpers/functions/enrichment/enrichmentHelper';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import history from '../../../infrastructure/helpers/react/history';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import IngestionConfigOption from '../../../types/ingestion/IngestionConfigOption';
import Operand from '../../../types/functions/Operand/Operand';
import PromiseButton from '../../Common/PromiseButton';
import React from 'react';
import ReportConfig from '../../../types/report/ReportConfig';
import reportPageMode from './ReportPage/ReportPageMode';
import ReportPageState from './ReportPage/ReportPageState';
import ReportRegimeDetails from '../../../types/report/ReportRegimeDetails';
import store from '../../../store/store';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';
import validateDataSources from '../DataSources/DataSourceForm.Validation';
import MatchingKey from '../../../types/report/MatchingKey';

interface ReportDataSourcePageState extends ReportPageState {
    isWaiting: boolean;
    error: string | null;
}

class ReportDataSourcePage extends React.Component<any, ReportDataSourcePageState>  {
    private dataSourceOptions: IngestionConfigOption[];
    private fieldIds: number[];
    private matchingKey: MatchingKey;

    constructor(props: any) {
        super(props);

        this.dataSourceOptions = [];
        this.fieldIds = [];
        this.matchingKey = { operands: [] as Operand[] } as MatchingKey;

        this.state = {
            isWaiting: true,
            reportConfig: getEmptyReportConfig(),
            error: null
        };

        this.onGetDataSourceOptionsSuccess = this.onGetDataSourceOptionsSuccess.bind(this);
        this.onGetRegimeDetailsSuccess = this.onGetRegimeDetailsSuccess.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
    }

    componentDidMount(): void {

        getDataSourceOptions(reportType.completenessFieldLevelMatching).then(this.onGetDataSourceOptionsSuccess);
    }

    onGetDataSourceOptionsSuccess(response: AxiosResponse<IngestionConfigOption[]>): void {

        response.data.forEach(x => this.dataSourceOptions.push(x));

        this.setState({ isWaiting: false });
    }

    onGetRegimeDetailsSuccess(response: AxiosResponse<ReportRegimeDetails>): void {

        clear(this.fieldIds);
        clear(this.matchingKey.operands);

        response.data.fieldIds.forEach(id => this.fieldIds.push(id));
        for (var i = 0; i < response.data.matchingKey.operands.length; i++) {
            this.matchingKey.operands.push(response.data.matchingKey.operands[i]);
        }

        this.setState({ isWaiting: false });
    }

    onChange(reportConfig: ReportConfig): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let isRegimeChange = stringHelper.isNonEmpty(reportConfig.regime);

        this.setState({ reportConfig: Object.assign({}, this.state.reportConfig, reportConfig), isWaiting: isRegimeChange }, () => {

            if (!isRegimeChange) {
                return;
            }

            getRegimeDetails(this.state.reportConfig).then(this.onGetRegimeDetailsSuccess);
        });
    }

    onSaveClick(): Promise<any> {
        let error = validateDataSources(this.state.reportConfig);

        this.setState({ error });

        if (error) {
            return Promise.reject(error);
        }

        let copy = copyObject(this.state.reportConfig);

        let $case = caseHelper.getEmpty(this.state.reportConfig.rawDataSourceId as number);

        $case.number = 1;
        $case.name = 'Reporting case 1';

        copy.cases.push($case);

        enrichmentHelper.handleCaseChanges(copy.cases, this.fieldIds);

        if (this.state.reportConfig.type === reportType.completenessFieldLevelMatching ||
            this.state.reportConfig.type === reportType.completenessRecordLevelMatching) {

            copy.matchingKey = this.matchingKey;
            copy.matchingKeyEndPoint = this.matchingKey;
        }

        return httpClient.post('report', copy).then(this.onSaveSuccess);
    }

    onSaveSuccess(): void {
        store.dispatch({ type: actions.navigation.execute, payload: () => history.push(appRoutes.reporting.home) });
    }

    render(): JSX.Element {
        return (
            <>
                <div className="row">
                    <div className="col-12 col-sm-11 col-md-10">
                        <DataSourceForm
                            isWaiting={this.state.isWaiting}
                            dataSources={this.dataSourceOptions}
                            reportConfig={this.state.reportConfig}
                            onChange={this.onChange}
                            mode={reportPageMode.create}
                            collections={this.props.collections}
                            hideReferences={true} />
                    </div>
                </div>

                <ErrorMessage error={this.state.error} />

                <div className="mt-3 mb-3">
                    <PromiseButton text="Save" className="btn cb-btn" enableOnErrorOnly={true} task={this.onSaveClick} />
                </div>
            </>
        );
    }
}

export default ReportDataSourcePage;
