import Action from './Action';
import ActionItemModalState from '../components/Common/Modals/ActionItemModal/ActionItemModalState';
import actions from './actions';
import ChangeLogModalState from '../components/Common/Modals/ChangeLogModal/ChangeLogModalState';
import ComplianceCommentModalState from '../components/Common/Modals/ComplianceCommentModal/ComplianceCommentModalState';
import ComplianceUrl from '../types/common/ComplianceUrl';
import ComplianceUrlEditModalState from '../components/Common/Modals/ComplianceUrlEditModal/ComplianceUrlEditModalState';
import CopyReportConfigModalState from '../components/Common/Modals/CopyReportConfigModal/CopyReportConfigModalState';
import CopyReportFieldModalState from '../components/Report/Enrichment/CopyReportFieldModal/CopyReportFieldModalState';
import CopyValidationModalState from '../components/Report/Validation/CopyValidationModal/CopyValidationModalState';
import CopyVariableModalState from '../components/Report/Variable/CopyVariableModal/CopyVariableModalState';
import GridRefresh from './state/events/GridRefresh';
import IngestionReplacementModalState from '../components/Ingestion/FieldDefinitions/IngestionReplacementModal/IngestionReplacementModalState';
import OperandModalState from '../components/Functions/Operand/Modal/OperandModalState';
import ReportConfigNavigationPayload from '../types/report/ReportConfigNavigationPayload';
import ReportFieldInstructionEditModalState from '../components/Common/Modals/ReportFieldInstructionEditModal/ReportFieldInstructionEditModalState';
import CreateVersionModalState from '../components/Common/Modals/CreateVersionModal/CreateVersionModalState';
import RollbackVersionModalState from '../components/Common/Modals/RollbackVersionModal/RollbackVersionModalState';
import ValidationChangeLogModalState from '../components/Report/Validation/ChangeLogModal/ValidationChangeLogModalState';
import CopyAggregationModalState from '../components/Report/Aggregation/CopyAggregationModal/CopyAggregationFieldModalState';
import SessionExpireState from '../components/Common/SessionExpire/SessionExpireState';
import AppState from './state/AppState';
import SelectRadioButtonState from '../components/Common/SelectRadioButton/SelectRadioButtonState';

const initialState: AppState = {
    action: '',
    navigate: () => { },
    sessionExpireState: {
        warningShowned: false,
        intervalId: 0
    },
    navigateToReportConfigSection: {} as ReportConfigNavigationPayload,
    gridRefresh: {} as GridRefresh,
    operandModalChange: {} as OperandModalState,
    operandModalSave: {} as OperandModalState,
    copyFieldModalChange: {} as CopyReportFieldModalState,
    copyFieldModalSave: {} as CopyReportFieldModalState,
    copyAggregationFieldModalChange: {} as CopyAggregationModalState,
    copyAggregationFieldModalSave: {} as CopyAggregationModalState,
    copyReportConfigModalState: {} as CopyReportConfigModalState,
    actionItemModalState: {} as ActionItemModalState,
    complianceCommentModalState: {} as ComplianceCommentModalState,
    complianceUrls: {} as ComplianceUrl[],
    changeLogModalState: {} as ChangeLogModalState,
    reportFieldInstructionEditModalState: {} as ReportFieldInstructionEditModalState,
    complianceUrlEditModalState: {} as ComplianceUrlEditModalState,
    ingestionReplacementModalState: {} as IngestionReplacementModalState,
    copyValidationModalChange: {} as CopyValidationModalState,
    copyValidationModalSave: {} as CopyValidationModalState,
    copyVariableModalChange: {} as CopyVariableModalState,
    copyVariableModalSave: {} as CopyVariableModalState,
    createVersionModalState: {} as CreateVersionModalState,
    rollBackVersionModalState: {} as RollbackVersionModalState,
    validationChangeLogModalState: {} as ValidationChangeLogModalState,
    selectedComponentState: {} as SelectRadioButtonState
}
const reducer = (state = initialState, action: Action): AppState => {

    let result = { ...state };
    result.action = action.type;

    switch (action.type) {

        case actions.navigation.execute:
            result.navigate = action.payload as () => void;
            break;

        case actions.navigation.reportConfigSection:
            result.navigateToReportConfigSection = action.payload as ReportConfigNavigationPayload;
            break;

        case actions.grid.refresh:
            result.gridRefresh = action.payload as GridRefresh;
            break;

        case actions.operandModal.change:
            result.operandModalChange = action.payload as OperandModalState;
            break;

        case actions.operandModal.save:
            result.operandModalSave = action.payload as OperandModalState;
            break;

        case actions.copyFieldModal.change:
            result.copyFieldModalChange = action.payload as CopyReportFieldModalState;
            break;

        case actions.copyFieldModal.save:
            result.copyFieldModalSave = action.payload as CopyReportFieldModalState;
            break;

        case actions.copyReportConfigModal.open:
            result.copyReportConfigModalState = action.payload as CopyReportConfigModalState;
            break;

        case actions.actionItemModal.open:
            result.actionItemModalState = action.payload as ActionItemModalState;
            break;

        case actions.complianceCommentModal.open:
            result.complianceCommentModalState = action.payload as ComplianceCommentModalState;
            break;

        case actions.complianceCommentModal.deleteCommentSuccess:
            result.complianceCommentModalState = action.payload as ComplianceCommentModalState;
            break;

        case actions.complianceUrlModal.open:
            result.complianceUrls = action.payload as ComplianceUrl[];
            break;

        case actions.changeLogModal.open:
            result.changeLogModalState = action.payload as ChangeLogModalState;
            break;

        case actions.reportFieldInstructionEditModal.open:
            result.reportFieldInstructionEditModalState = action.payload as ReportFieldInstructionEditModalState;
            break;

        case actions.complianceUrlEditModal.open:
            result.complianceUrlEditModalState = action.payload as ComplianceUrlEditModalState;
            break;

        case actions.ingestionReplacementModal.open:
        case actions.ingestionReplacementModal.save:
            result.ingestionReplacementModalState = action.payload as IngestionReplacementModalState;
            break;

        case actions.copyValidationModal.change:
            result.copyValidationModalChange = action.payload as CopyValidationModalState;
            break;

        case actions.copyValidationModal.save:
            result.copyValidationModalSave = action.payload as CopyValidationModalState;
            break;

        case actions.copyVariableModal.change:
            result.copyVariableModalChange = action.payload as CopyVariableModalState;
            break;

        case actions.copyVariableModal.save:
            result.copyVariableModalSave = action.payload as CopyVariableModalState;
            break;

        case actions.createVersionModal.open:
            result.createVersionModalState = action.payload as CreateVersionModalState;
            break;

        case actions.rollBackVersionModal.open:
            result.rollBackVersionModalState = action.payload as RollbackVersionModalState;
            break;

        case actions.validationChangeLogModal.open:
            result.validationChangeLogModalState = action.payload as ValidationChangeLogModalState;
            break;

        case actions.copyAggregationFieldModal.change:
            result.copyAggregationFieldModalChange = action.payload as CopyAggregationModalState;
            break;

        case actions.copyAggregationFieldModal.save:
            result.copyAggregationFieldModalSave = action.payload as CopyAggregationModalState;
            break;

        case actions.sessionExpire.warningShowned:
            result.sessionExpireState = { ...result.sessionExpireState, ...action.payload } as SessionExpireState;
            break;
        case actions.sessionExpire.intervalId:
            result.sessionExpireState = { ...result.sessionExpireState, ...action.payload } as SessionExpireState;
            break;
        case actions.component.selected:
            result.selectedComponentState = action.payload as SelectRadioButtonState;
            break;
    }

    return result;
};

export default reducer;
