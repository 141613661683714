import DeclarationHelper from '../../../types/Functions/DeclarationHelper';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import DictionaryField from '../../../../types/report/DictionaryField';
import Dictionary from '../../../../types/report/Dictionary';
import assignmentHelper from '../assignment/assignmentHelper';
import dataType from '../../../constants/dataType';

class DictionaryFieldHelper implements DeclarationHelper<DictionaryField> {

    private _dictionaryNumber: number;

    constructor(dictionaryNumber: number) {

        this._dictionaryNumber = dictionaryNumber;
    }

    add(declarations: DictionaryField[], declaration: DictionaryField): void {

        declaration.number = declarations.length + 1;

        declarations.push(declaration);
    }

    remove(declarations: DictionaryField[], location: ReportLogicLocation): DictionaryField[] {

        return declarations.filter(x => x.number !== location.fieldId);
    }

    getDeclarations(config: ReportConfig, location: ReportLogicLocation): DictionaryField[] {

        let dictionary = this.getDictionary(config, location);

        return dictionary.fields;
    }

    getDeclaration(declarations: DictionaryField[], location: ReportLogicLocation): DictionaryField {

        let declaration = declarations.find(x => x.number === location.fieldId) as DictionaryField;

        return declaration;
    }

    getDataSourceId(config: ReportConfig, location: ReportLogicLocation): number {

        let dictionary = this.getDictionary(config, location);

        return dictionary.dataSourceId;
    }

    getEmpty(rawDataSourceId: number | null): DictionaryField {

        let assignment = assignmentHelper.getEmpty(false);
        assignment.number = 1;

        return {
            id: null,
            name: '',
            businessDescription: '',
            number: 0,
            assignment: assignment,
            dataType: dataType.string,
            errors: []
        };
    }

    private getDictionary(config: ReportConfig, location: ReportLogicLocation): Dictionary {

        return config.dictionaries.find(x => x.number === this._dictionaryNumber) as Dictionary;
    }
}

const dictionaryFieldHelper = (dictionaryNumber: number): DictionaryFieldHelper => new DictionaryFieldHelper(dictionaryNumber);

export default dictionaryFieldHelper;
