const actions = {
    common: {
        closeModal: 'common-close-modal'
    },
    navigation: {
        setUnsafe: 'navigation-set-unsafe',
        execute: 'navigation-execute',
        reportConfigSection: 'navigation-report-config-section'
    },
    grid: {
        refresh: 'grid-refresh'
    },
    operandModal: {
        change: 'operand-modal-change',
        save: 'operand-modal-save'
    },
    copyFieldModal: {
        change: 'field-copy-modal-change',
        save: 'field-copy-modal-save'
    },
    copyReportConfigModal: {
        open: 'copy-report-config-modal-open',
        saveSuccess: 'copy-report-config-modal-save-success'
    },
    copyAggregationFieldModal: {
        change: 'copy-aggregation-field-modal-change',
        save: 'copy-aggregation-field-modal-save'
    },
    actionItemModal: {
        open: 'action-item-modal-open',
        saveSuccess: 'action-item-modal-save-success'
    },
    complianceCommentModal: {
        open: 'compliance-comment-modal-open',
        saveSuccess: 'compliance-comment-modal-save-success',
        deleteCommentSuccess: 'compliance-comment-modal-delete-success'
    },
    complianceUrlModal: {
        open: 'compliance-url-modal-open'
    },
    changeLogModal: {
        open: 'change-log-modal-open'
    },
    reportFieldInstructionEditModal: {
        open: 'report-field-instruction-edit-modal-open',
        saveSuccess: 'report-field-instruction-edit-modal-save-success'
    },
    complianceUrlEditModal: {
        open: 'compliance-url-edit-modal-open',
        saveSuccess: 'compliance-url-edit-modal-save-success'
    },
    importReportConfigModal: {
        open: 'import-report-config-modal-open',
        saveSuccess: 'import-report-config-modal-save-success'
    },
    ingestionReplacementModal: {
        open: 'ingestion-replacement-modal-open',
        save: 'ingestion-replacement-modal-save'
    },
    selectors: {
        open: 'selectors-open',
        index: 'index',
        save: 'selectors-save',
        isinChanged: 'isin-changed',
        leiChanged: 'lei-changed',
        exchangeRatesChanged: 'exchange-rates-changed',
        annaDsbUpiEnrichmentChanged: 'anna-dsb-upi-enrichment-changed',
        annaDsbUpiChanged: 'anna-dsb-upi-changed',
        lseChanged: 'lse-changed',
        firdsFcaInstrumentChange: 'first-instrument-changed',
        firdsEsmaInstrumentChange: 'first-instrument-changed',
        fcaRegulatedEntitiesChange: 'fca-regulated-entities-changed',
    },
    copyValidationModal: {
        change: 'validation-copy-modal-change',
        save: 'validation-copy-modal-save'
    },
    copyVariableModal: {
        change: 'variable-copy-modal-change',
        save: 'variable-copy-modal-save'
    },
    createVersionModal: {
        open: 'create-version-modal-open'
    },
    rollBackVersionModal: {
        open: 'rollback-version-modal-open'
    },
    validationChangeLogModal: {
        open: 'validation-change-log-modal-open'
    },
    sessionExpire: {
        warningShowned: 'showned',
        intervalId: 'intervalId'
    },
    component: {
        selected: 'component-selected'
    }
};

export default actions;
