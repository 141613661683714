import { ReactComponent as RemoveIcon } from '../../../Common/BootstrapIcons/close.svg';
import ColumnDefinition from '../../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../../Common/BootstrapIcons/Custom/CustomIcon';
import FieldDefinition from '../../../../types/common/FieldDefinition';
import iconTooltip from '../../../Common/BootstrapIcons/Tooltip/IconTooltip';
import React from 'react';
import InfoIcon from '../../../Common/InfoIcon/InfoIcon';
import guidance from '../FieldDefinitions.Guidance';
import systemField from '../../../../infrastructure/constants/ingestion/systemField';

const getColumns = (isReadOnly: boolean, onRemoveClick: (i: number, name: string) => void): ColumnDefinition<FieldDefinition>[] => {

    return [
        {
            header: 'Name',
            getContent: x => getInfoContent(x.name),
            width: '230px'
        },
        {
            header: 'Type',
            getContent: x => x.type
        },
        {
            header: '',
            getContent: (x, i) => isReadOnly ?
                <></> :
                <button
                    className="custom-icon-button"
                    onClick={() => onRemoveClick(i, x.name)}>
                    <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                </button>,
            width: '34px'
        }
    ];
};

const getInfoContent = (name: string): JSX.Element => {
    if (name === systemField.dateTimeFromFilename) {
        return (
            <span><InfoIcon info={guidance.systemFieldFileName as string} />{name}</span>
        );
    }
    else {
        return (
            <span>{name}</span>
        );
    }
}

export { getColumns };