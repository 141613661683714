import React from 'react';
import './validationEsmaTable.scss';

const createValidationEsmaTable = (tableRows?: string[][], onChange?: (e: any, rowIndex: number, cellIndex: number) => void): JSX.Element => {

    tableRows = tableRows || [[]];

    const headers = ['', 'NEWT', 'MODI', 'VALU', 'CORR', 'TERM', 'EROR', 'REVI', 'POSC', 'MARU'];

    return (
        <div id='validationsEsmaTable'>
            <div>
                <div key='headerRow' className='display-flex'>
                    {headers.map((header: string) => {
                        return <div className='header' key={header}>{header || ''}</div>
                    })
                    }
                </div>
            </div>
            <div className='tbody'>
                {tableRows.map((row: string[], rowIndex: number) => {
                    return <div key={rowIndex} className='tr' >
                        {row.map((value: string, cellIndex: number) => {
                            return <input disabled={cellIndex > 0 && !onChange} type="text" className='td input' key={cellIndex} onChange={(e: any) => onChange ? onChange(e, rowIndex, cellIndex) : null} value={value ?? ''} />
                        })
                        }
                    </div>
                })
                }
            </div>
        </div>
    )
}


export { createValidationEsmaTable } 
