import { AxiosResponse } from 'axios';
import { getColumns } from './EnrichmentBuilder.Grid';
import actionItemHelper from '../../../../infrastructure/helpers/actionItem/actionItemHelper';
import ActionItemSaveRequest from '../../../../types/common/ActionItem/ActionItemSaveRequest';
import actions from '../../../../store/actions';
import AssignmentBuilder from '../../../Functions/Assignment/AssignmentBuilder';
import assignmentDisplayHelper from '../../../../infrastructure/helpers/functions/assignment/assignmentDisplayHelper';
import assignmentHelper from '../../../../infrastructure/helpers/functions/assignment/assignmentHelper';
import ComplianceComment from '../../../../types/common/ComplianceComment';
import complianceCommentHelper from '../../../../infrastructure/helpers/complianceComment/complianceCommentHelper';
import ComplianceUrl from '../../../../types/common/ComplianceUrl';
import CopyReportFieldModalState from '../CopyReportFieldModal/CopyReportFieldModalState';
import DeclarationBuilderProps from '../../../Functions/Declaration/DeclarationBuilderProps';
import EditableText from '../../../Common/EditableText/EditableText';
import enrichmentHelper from '../../../../infrastructure/helpers/functions/enrichment/enrichmentHelper';
import ErrorList from '../../../Common/ErrorList/ErrorList';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import locationType from '../../../../types/functions/Location/LocationType';
import ModalState from '../../../Common/Modal/ModalState';
import pseudocodeHelper from '../../../../infrastructure/helpers/functions/common/pseudocodeHelper';
import React from 'react';
import ReportCaseField from '../../../../types/report/ReportCaseField';
import reportConfigNavigation from '../../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import reportFieldHelper from '../../../../infrastructure/helpers/functions/common/reportFieldHelper';
import ReportFieldInstructions from '../../../../types/report/ReportFieldInstructions';
import ReportFieldInstructionsModal from '../ReportFieldInstructionsModal/ReportFieldInstructionsModal';
import ReportLogicList from '../../Common/ReportLogicList/ReportLogicList';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import reportLogicReferenceHelper from '../../../../infrastructure/helpers/functions/report/reportLogicReferenceHelper';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import store from '../../../../store/store';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../../infrastructure/helpers/common/urlHelper';
import identityStorage from '../../../../infrastructure/authorization/identityStorage';
import ActionItemDto from '../../../../types/common/ActionItem/ActionItemDto';
import ActionitemDto from '../../../../types/common/ActionItem/ActionItemDto';
import reportRegime from '../../../../infrastructure/constants/reportRegime';

class EnrichmentBuilder extends React.Component<DeclarationBuilderProps<ReportCaseField>, ModalState<ReportFieldInstructions>> {

    constructor(props: DeclarationBuilderProps<ReportCaseField>) {
        super(props);

        this.state = {
            title: '',
            isOpen: false,
            context: {
                detailsToBeReported: '',
                detailsToBeReportedUrl: '',
                formatToBeReported: '',
                formatToBeReportedUrl: '',
                validationEsmaTableRows: [[]],
                validations: '',
                validationsUrl: '',
                cnPopulationGuidelines: '',
                cnPopulationGuidelinesUrl: '',
                rtsFieldNumber: '',
                t: '',
                p: ''
            },
            error: null
        };

        this.onBespokeToggleClick = this.onBespokeToggleClick.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
        this.onCommentClick = this.onCommentClick.bind(this);
        this.onActionItemClick = this.onActionItemClick.bind(this);
        this.onUrlClick = this.onUrlClick.bind(this);
        this.onCopyFieldClick = this.onCopyFieldClick.bind(this);
        this.onResetAssignmentClick = this.onResetAssignmentClick.bind(this);
        this.onGetInfoSuccess = this.onGetInfoSuccess.bind(this);
        this.onGetCommentSuccess = this.onGetCommentSuccess.bind(this);
        this.onGetActionItemDataSuccess = this.onGetActionItemDataSuccess.bind(this);
        this.onGetUrlSuccess = this.onGetUrlSuccess.bind(this);
        this.getFieldReferences = this.getFieldReferences.bind(this);
        this.getCommentCount = this.getCommentCount.bind(this);
        this.getUrlCount = this.getUrlCount.bind(this);
        this.getActionItemCount = this.getActionItemCount.bind(this);
        this.getFieldLocation = this.getFieldLocation.bind(this);
        this.isSyncedCase = this.isSyncedCase.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getAssignmentContent = this.getAssignmentContent.bind(this);
        this.getCaseField = this.getCaseField.bind(this);
        this.isBespokeField = this.isBespokeField.bind(this);
    }

    onBespokeToggleClick(isBespoke: boolean, definition: ReportFieldDefinition): void {

        let location = this.getFieldLocation(definition.id);

        let caseField = this.getCaseField(location);

        let changes = { isBespoke: isBespoke } as ReportCaseField;

        if (isBespoke) {

            changes.bespokeAssignment = assignmentDisplayHelper.buildAssignment(definition.name, caseField.assignment, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields);
        }
        else if (!typeHelper.isObject(caseField.assignment)) {

            changes.assignment = assignmentHelper.getEmpty(true);
            changes.assignment.number = 1;
        }

        this.props.onDeclarationChange(location, changes);
    }

    onInfoClick(id: number): Promise<any> {

        let field = reportFieldHelper.getById(this.props.collections.reportFields, id);

        return httpClient.get<ReportFieldInstructions>('report-field/' + id, { regime: this.props.reportConfig.regime }).then(response => this.onGetInfoSuccess(response.data, field.name));
    }

    onCommentClick(id: number): Promise<any> {

        let parts = ['report-field-comment', id];

        if (typeHelper.isNumber(this.props.reportConfig.id)) {

            parts.push(this.props.reportConfig.id as number);
        }

        let url = urlHelper.buildRoute(parts);

        return httpClient.get<ComplianceComment[]>(url).then(response => this.onGetCommentSuccess(response.data, url));
    }

    onActionItemClick(id: number): Promise<any> {

        let identity = identityStorage.get();

        let allItemsForClient = urlHelper.buildRoute(['action-item', 'by-client-id']);

        let enrichmentActionItems = urlHelper.buildRoute(['action-item', 'report-field', this.props.reportConfig.id, id]);

        let buildRelationUrl = (actionItemId: string) => urlHelper.buildRoute(['action-item', actionItemId, 'report-field', id]);

        let calls: [Promise<AxiosResponse<ActionitemDto[]>>, Promise<AxiosResponse<ActionitemDto[]>>] = [httpClient.get<ActionitemDto[]>(allItemsForClient, { reportConfigId: this.props.reportConfig.id, clientId: identity.clientId || null }), httpClient.get<ActionitemDto[]>(enrichmentActionItems)];

        let saveRequest = { reportConfigId: this.props.reportConfig.id, fieldId: id } as ActionItemSaveRequest;

        return Promise.all(calls).then(responses => this.onGetActionItemDataSuccess(responses[0].data, responses[1].data, saveRequest, buildRelationUrl));
    }

    onUrlClick(id: number): Promise<any> {

        let url = urlHelper.buildRoute(['report-field', id, 'urls']);

        return httpClient.get<ComplianceUrl[]>(url).then(this.onGetUrlSuccess);
    }

    onCopyFieldClick(id: number): void {

        let state: CopyReportFieldModalState = {
            title: 'Copy Field',
            isOpen: true,
            error: null,
            copyAllFields: false,
            sourceCaseNumber: (this.props.declarationBatchNumber as number).toString(),
            targetCaseNumbers: new Set([(this.props.declarationBatchNumber || '').toString()]),
            sourceFieldId: id.toString(),
            targetFieldId: ''
        };

        // on opening modal
        store.dispatch({ type: actions.copyFieldModal.change, payload: state });
    }

    onResetAssignmentClick(location: ReportLogicLocation): void {

        this.props.onResetAssignmentClick(location, true);
    }

    onGetInfoSuccess(instructions: ReportFieldInstructions, name: string): void {

        this.setState({ context: instructions, title: name, isOpen: true });
    }

    onGetCommentSuccess(comments: ComplianceComment[], url: string): void {

        let state = complianceCommentHelper.createModalState(comments, url);

        store.dispatch({ type: actions.complianceCommentModal.open, payload: state });
    }

    onGetActionItemDataSuccess(allActionItems: ActionItemDto[], enrichmentActionItems: ActionitemDto[], saveRequest: ActionItemSaveRequest, buildRelationUrl: (actionItemId: string) => string): void {

        let originalActionItems: ActionItemDto[] = [];

        allActionItems.forEach((iten) => {
            originalActionItems.push(Object.assign({}, iten));
        });

        const enrichmentItems = actionItemHelper.prepareModuleActionitems(allActionItems, enrichmentActionItems);

        let state = actionItemHelper.createModalState(originalActionItems, allActionItems, enrichmentItems, saveRequest, buildRelationUrl, 'Enrichment');

        store.dispatch({ type: actions.actionItemModal.open, payload: state });
    }

    onGetUrlSuccess(response: AxiosResponse<ComplianceUrl[]>): void {

        store.dispatch({ type: actions.complianceUrlModal.open, payload: response.data });
    }

    getCaseField(location: ReportLogicLocation): ReportCaseField {

        let fields = enrichmentHelper.getDeclarations(this.props.reportConfig, location);

        return enrichmentHelper.getDeclaration(fields, location);
    }

    isBespokeField(fieldId: number): boolean {

        let location = this.getFieldLocation(fieldId);

        let field = this.getCaseField(location);

        return field.isBespoke;
    }

    getFieldReferences(fieldId: number): SelectOption[] {

        return reportLogicReferenceHelper.getFieldReferences(fieldId, this.props.declarationBatchNumber as number, this.props.reportConfig, this.props.collections.reportFields);
    }

    getCommentCount(fieldId: number): number {
        return (
            (this.props.counts.reportFieldCommentCounts[fieldId.toString()] || 0) +
            (this.props.counts.fieldDefinitionCommentCounts[fieldId.toString()] || 0)
        );
    }

    getUrlCount(fieldId: number): number {

        return this.props.counts.fieldDefinitionUrlCounts[fieldId.toString()] || 0;
    }

    getActionItemCount(id: number): number {

        return this.props.counts.reportFieldActionItemCounts[id.toString()] || 0;
    }

    getFieldLocation(fieldId: number): ReportLogicLocation {
        return {
            statement: locationType.statement.reportField,
            statementNumber: this.props.declarationBatchNumber,
            fieldId: fieldId
        } as ReportLogicLocation;
    }

    isSyncedCase(): boolean {
        let $case = this.props.reportConfig.cases.find(x => x.number === this.props.declarationBatchNumber);

        return typeHelper.isNumber($case?.syncedWithId);
    }

    closeModal(): void {
        this.setState({ isOpen: false });
    }

    onReorderConditions(field: ReportCaseField, error?: string) {

        if (error && !field.errors.includes(error)) {
            field.errors.push(error);
        }
        let location = this.getFieldLocation(field.fieldId);
        this.props.onDeclarationChange(location, field);
    }

    getAssignmentContent(definition: ReportFieldDefinition, isReadOnly: boolean): JSX.Element {

        let location = this.getFieldLocation(definition.id);

        let field = this.getCaseField(location);

        if (field?.isBespoke) {
            return (
                <>
                    <div className="code-block">
                        <EditableText
                            className="code"
                            multiline={true}
                            value={field.bespokeAssignment}
                            onChange={value => this.props.onDeclarationChange(location, { bespokeAssignment: value } as ReportCaseField)}
                            disabled={isReadOnly} />
                    </div>
                    <ErrorList errors={field.errors} className="mt-2" />
                </>
            );
        }

        return (
            <>
                <AssignmentBuilder
                    isReadOnly={isReadOnly}
                    location={location}
                    assignment={field.assignment}
                    fieldName={pseudocodeHelper.wrapInBrackets(definition.name)}
                    functions={this.props.reportConfig.userDefinedFunctions}
                    lookups={this.props.reportConfig.lookups}
                    dictionaries={this.props.reportConfig.dictionaries}
                    variables={this.props.reportConfig.variables}
                    dataSources={this.props.collections.dataSources}
                    reportFields={this.props.collections.reportFields}
                    customDataSourceFields={this.props.collections.customDataSourceFields}
                    onAddAssignmentClick={this.props.onAddAssignmentClick}
                    onRemoveAssignmentClick={this.props.onRemoveAssignmentClick}
                    onResetAssignmentClick={this.onResetAssignmentClick}
                    onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                    onParenthesesChange={this.props.onParenthesesChange}
                    onAddConditionClick={this.props.onAddConditionClick}
                    onRemoveConditionClick={this.props.onRemoveConditionClick}
                    onComparisonTypeChange={this.props.onComparisonTypeChange}
                    onOperandClick={this.props.onOperandClick}
                    onCopyClick={this.props.onCopyClick}
                    onPasteClick={this.props.onPasteClick}
                    onValidateFieldClick={this.props.onValidateDeclarationClick}
                    allowPaste={this.props.allowPaste}
                    onReorderAssignmentConditions={(error) => { this.onReorderConditions(field, error); }} />

                <ErrorList errors={field.errors} className="mt-2" />
            </>
        );
    }

    render(): JSX.Element {

        let isReadOnly = this.props.isReadOnly || this.isSyncedCase();

        return (
            <>
                <ReportLogicList
                    isWaiting={this.props.isWaiting}
                    items={this.props.collections.reportFields.filter(x => !x.isHidden)}
                    columns={getColumns(
                        this.onInfoClick,
                        this.onCommentClick,
                        this.onUrlClick,
                        this.onActionItemClick,
                        this.onCopyFieldClick,
                        this.onBespokeToggleClick,
                        this.getFieldReferences,
                        this.getCommentCount,
                        this.getUrlCount,
                        this.getActionItemCount,
                        this.isBespokeField,
                        isReadOnly,
                        `${this.props.reportConfig.regime === reportRegime['ASIC Rewrite'] ? 'ASIC Field Number' : 'RTS Field Number'}`)}
                    getContent={x => this.getAssignmentContent(x, isReadOnly)}
                    getKey={x => x.number}
                    createHtmlId={x => reportConfigNavigation.buildReportFieldId(x.id)} />

                <ReportFieldInstructionsModal state={this.state} close={this.closeModal} regime={this.props.reportConfig.regime} />
            </>
        );
    }
}

export default EnrichmentBuilder;
