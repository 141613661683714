import { mapToBasicOption } from '../../../../../infrastructure/helpers/html/selectOptionHelper';
import booleanFormatString from '../../../../../infrastructure/constants/functions/formatString/types/booleanFormatString';
import ObjectIndexer from '../../../../../infrastructure/types/ObjectIndexer';
import operandSpecialValueType from '../../../../../infrastructure/constants/functions/operandSpecialValueType';
import SelectOption from '../../../../../infrastructure/types/SelectOption';
import timeZones from '../../../../../infrastructure/constants/timeZones/timeZones';

const buildTimeZoneOption = (key: string, pairs: ObjectIndexer<string>): SelectOption => {
    return {
        text: `${key} (${pairs[key]})`,
        value: key
    };
};

const booleanFormatOptions = Object.values(booleanFormatString).map(mapToBasicOption);

const timeZoneOptions = Object.keys(timeZones).map(x => buildTimeZoneOption(x, timeZones as ObjectIndexer<string>));

const dateTimeFormatSpecialValueTypes = [
    operandSpecialValueType.formatStringDateTime,
    operandSpecialValueType.formatStringDate,
    operandSpecialValueType.formatStringTime
];

export {
    booleanFormatOptions,
    timeZoneOptions,
    dateTimeFormatSpecialValueTypes
};