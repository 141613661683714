import { getColumns, uniqueName } from './ReportHomePage.Grid';
import { Unsubscribe } from 'redux';
import actions from '../../../store/actions';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import CopyReportConfigModal from '../../Common/Modals/CopyReportConfigModal/CopyReportConfigModal';
import CopyReportConfigModalState from '../../Common/Modals/CopyReportConfigModal/CopyReportConfigModalState';
import GridRefresh from '../../../store/state/events/GridRefresh';
import history from '../../../infrastructure/helpers/react/history';
import identityStorage from '../../../infrastructure/authorization/identityStorage';
import React from 'react';
import ReportConfigGridItem from '../../../types/report/ReportConfigGridItem';
import ServerDataGrid from '../../Common/DataGrid/ServerDataGrid/ServerDataGrid';
import store from '../../../store/store';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import writeUserRoles from '../../../infrastructure/constants/identity/writeUserRoles';
import ImportReportConfigModal from '../../Common/Modals/ImportReportConfigModal/ImportReportConfigModal';
import DownloadModal from '../../Common/Modals/DownloadModal/DownloadModal';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';
import { AxiosResponse } from 'axios';
import reportConfigBundleSerializer from '../../../infrastructure/helpers/report/import/reportConfigBundleSerializer';
import dataSourceKind from '../../../infrastructure/constants/dataSourceKind';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import ReportConfig from '../../../types/report/ReportConfig';
import { getConfigDataSources } from '../Import/ServerCalls';
import { getReportDataSources, getReportFields } from './ReportPage/ReportPage.Helpers';
import './ReportHomePage.scss';
import validateConfigName from '../../../infrastructure/helpers/common/validator/nameValidator';
interface State {
    enabled: boolean;
    name: string;
    showDownloadPopup: boolean;
    reportConfigId: number;
    showWarningModal: boolean;
    message: string;
    removeConfig: ReportConfigGridItem;
    showSpinnerForId: number;
}

class ReportHomePage extends React.Component<any, State> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = { showSpinnerForId: 0, enabled: true, name: '', showDownloadPopup: false, reportConfigId: 0, showWarningModal: false, message: '', removeConfig: {} as ReportConfigGridItem };

        this.refreshGridListener = this.refreshGridListener.bind(this);
        this.onEnabledChange = this.onEnabledChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onDownloadTypeSelected = this.onDownloadTypeSelected.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onRemoveConfirmed = this.onRemoveConfirmed.bind(this);
        this.onCopyConfigSourceClick = this.onCopyConfigSourceClick.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.refreshGridListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    refreshGridListener(): void {

        let state = store.getState();

        let proceed =
            state.action === actions.copyReportConfigModal.saveSuccess ||
            state.action === actions.importReportConfigModal.saveSuccess;

        if (!proceed) {
            return;
        }

        this.refreshGrid();
    }

    onCreateNewClick() {
        history.push(appRoutes.reporting.dataSources);
    }

    onImportClick() {

        store.dispatch({ type: actions.importReportConfigModal.open, payload: null as any });
    }

    onEnabledChange(e: React.ChangeEvent<HTMLInputElement>): void {

        this.setState({ enabled: !e.target.checked }, this.refreshGrid);
    }

    onNameChange(e: React.ChangeEvent<HTMLInputElement>): void {

        this.setState({ name: e.target.value });
    }

    onSearchClick(): void {
        this.refreshGrid();
    }

    onSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        this.refreshGrid();
    }

    onEditClick(id: number): void {

        let route = urlHelper.buildUrl(appRoutes.reporting.edit, { id: id });

        history.push(route);
    }

    onCopyClick(config: ReportConfigGridItem) {

        let state = {} as CopyReportConfigModalState;

        state.id = config.id;
        state.name = `${config.name} - Copy`;
        state.regimeOriginal = config.regime;
        state.regimeOverride = config.regime;
        state.selectedRegime = config.regime;
        state.selectedSubRegime = config.subRegime || '';
        state.validate = validateConfigName;
        state.isOpen = true
        store.dispatch({ type: actions.copyReportConfigModal.open, payload: state });
    }

    refreshGrid(): void {

        let payload: GridRefresh = {
            uniqueName: uniqueName,
            useCurrentPage: false
        };

        store.dispatch({ type: actions.grid.refresh, payload: payload });
    }

    onDownloadClick(id: number): Promise<void> {

        this.setState({ showDownloadPopup: true, reportConfigId: id });
        return Promise.resolve();
    }

    onDownloadTypeSelected(downloadAsFragmented: boolean): void {

        this.setState({ showDownloadPopup: false });
        let route = urlHelper.buildRoute(['report', this.state.reportConfigId, downloadAsFragmented ? 'export-fragmented' : 'export-full']);

        httpClient.download(route);
    }

    onRemoveClick(config: ReportConfigGridItem) {

        let message = `Are you sure you want to delete config ${config.name}?`;

        this.setState({ message, showWarningModal: true, removeConfig: config });
    }

    onRemoveConfirmed() {

        this.setState({ showWarningModal: false });

        let route = urlHelper.buildRoute(['report', this.state.removeConfig.id]);

        return httpClient.delete(route).then(this.onSuccess);
    }


    onSuccess(response: AxiosResponse): void {

        let payload: GridRefresh = {
            uniqueName: uniqueName,
            useCurrentPage: true
        };

        store.dispatch({ type: actions.grid.refresh, payload: payload });
    };

    async onCopyConfigSourceClick(id: number): Promise<void> {

        this.setState({ showSpinnerForId: id });

        let url = 'report/' + id;

        var response = await httpClient.get<ReportConfig>(url);

        let res = await getReportDataSources(response.data);

        let dataSources = await getConfigDataSources(res.data.filter(x => x.dataSourceKind !== dataSourceKind.globalRef).map(x => x.id as number),
            res.data.filter(x => x.dataSourceKind === dataSourceKind.globalRef).map(x => x.id as number)
        );

        let reportFields = (await getReportFields(response.data)).data;

        let json = reportConfigBundleSerializer.serialize(response.data, dataSources, reportFields);

        await navigator.clipboard.writeText(json);

        this.setState({ showSpinnerForId: 0 });
    }

    async onGetDataSourceSuccess(dataSources: IngestionConfig[]): Promise<void> {

        let json = reportConfigBundleSerializer.serialize(this.props.config, dataSources, this.props.collections.reportFields);

        await navigator.clipboard.writeText(json);
    }

    render(): JSX.Element {

        let identity = identityStorage.get();

        return (
            <>
                <h3>Reporting Configs {this.state.enabled ? '' : '(archived)'}</h3>

                {
                    writeUserRoles.includes(identity.userRole) &&
                    <div className="mt-4">
                        <button type="button" className="btn cb-btn" onClick={this.onCreateNewClick}>Create New Reporting Config</button>
                        <button type="button" className="btn btn-light" onClick={this.onImportClick}>Import Reporting Config</button>
                    </div>
                }

                <div className="row no-gutters mt-4 mb-2">
                    <div className="col-auto">
                        <form onSubmit={this.onSubmit}>
                            <input
                                type="text"
                                value={this.state.name}
                                onChange={this.onNameChange}
                                className="form-control"
                                placeholder="Search By Name or Id" />
                        </form>
                    </div>
                    <div className="col-auto ml-1">
                        <button className="btn btn-light" onClick={this.onSearchClick}>Search</button>
                    </div>
                    <div className="col ml-2">
                        <label>
                            <input
                                type="checkbox"
                                checked={!this.state.enabled}
                                onChange={this.onEnabledChange} />
                            <span>Archived</span>
                        </label>
                    </div>
                </div>

                <div className="mb-4">
                    <ServerDataGrid
                        columns={getColumns(this.onEditClick, this.onCopyClick, this.state.enabled, this.onDownloadClick, this.onRemoveClick, this.onCopyConfigSourceClick, this.state.showSpinnerForId)}
                        uniqueName={uniqueName}
                        dataSourceRoute="report/page"
                        pageSize={10}
                        getKey={x => x.id}
                        getAdditionalParams={() => this.state} />
                </div>

                <DownloadModal
                    onClose={() => { this.setState({ showDownloadPopup: false }) }}
                    onDownload={this.onDownloadTypeSelected}
                    isOpen={this.state.showDownloadPopup} />
                <CopyReportConfigModal />
                <ImportReportConfigModal />
                {
                    this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onRemoveConfirmed}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Remove config'
                        message={this.state.message} />
                }
            </>
        );
    }
}

export default ReportHomePage;
