import Operand from "../../../types/functions/Operand/Operand";
import ComponentType from "../../../types/report/ComponentType";
import NameAndNumber from "../../../types/report/NameAndNumber";
import ReportConfig from "../../../types/report/ReportConfig";
import operandHelper from "../functions/operand/operandHelper";

const reorder = <T>(collection: T[], dragIndex: number, dropIndex: number): void => {

    var temp = collection[dragIndex];
    collection[dragIndex] = collection[dropIndex];
    collection[dropIndex] = temp;
};

const reorderWithNumbersChange = (collection: NameAndNumber[], dragIndex: number, dropIndex: number): void => {

    var temp = Object.assign({}, collection[dragIndex]);
    var dragNumber = collection[dragIndex].number;
    var dropNumber = collection[dropIndex].number;

    collection[dragIndex] = Object.assign({}, collection[dropIndex]);
    collection[dragIndex].number = dragNumber;
    collection[dropIndex] = temp;
    collection[dropIndex].number = dropNumber;
};

const rearangeElementsWhenAddNewOne = (elements: NameAndNumber[], element: NameAndNumber, isInsertAfter: boolean, insertAfterVariableNumber: number) => {

    const indexOfNewVariable = elements.findIndex(v => v.number === element.number);
    if (indexOfNewVariable > -1) {
        elements.splice(indexOfNewVariable, 1);
    }

    var indexOfTargetVariable = elements.findIndex(v => v.number === insertAfterVariableNumber);
    var newPositionInArray = isInsertAfter ? indexOfTargetVariable + 1 : indexOfTargetVariable;
    elements.splice(newPositionInArray, 0, element);
    resetElementsNumbers(elements);

    return elements;
}

const resetElementsNumbers = (elements: NameAndNumber[]) => {
    for (var i = 0; i < elements.length; i++) {
        elements[i]._internalId = elements[i].number;
        elements[i].number = i + 1;
    }
    return elements;
}

const rearangeElementsWhenRemoveOne = (elements: NameAndNumber[]) => {
    elements.map(x => x.number).sort((x, y) => { return x > y ? 1 : x < y ? -1 : 0; });
    resetElementsNumbers(elements)

    return elements;
}

const changeReferences = (reportConfig: ReportConfig, collection: NameAndNumber[], callback: (operand: Operand, oldAndNewNumbers: Map<number, number>, componentType?: ComponentType | undefined) => void) => {

    let oldAndNewNumbers = new Map<number, number>();

    collection.forEach((item) => {
        oldAndNewNumbers.set(item._internalId, item.number);
    });

    operandHelper.traverseReportConfigSectionsForOperands(reportConfig, (operand: Operand, itemName?: string | undefined, componentType?: ComponentType | undefined) => { callback(operand, oldAndNewNumbers, componentType) });
};

const reorderHelper = {
    rearangeElementsWhenAddNewOne,
    rearangeElementsWhenRemoveOne,
    changeReferences,
    reorder,
    reorderWithNumbersChange
};

export default reorderHelper;
