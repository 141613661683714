import './ReportFieldInstructionEditModal.scss';
import { Unsubscribe } from 'redux';
import { validate } from './ReportFieldInstructionEditModal.Validation';
import actions from '../../../../store/actions';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import Modal from '../../Modal/Modal';
import PromiseButton from '../../PromiseButton';
import React from 'react';
import ReportFieldInstructionEditModalState from './ReportFieldInstructionEditModalState';
import ReportFieldInstructions from '../../../../types/report/ReportFieldInstructions';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import store from '../../../../store/store';
import reportRegime from '../../../../infrastructure/constants/reportRegime';
import { createValidationEsmaTable } from './ReportFieldInstructionHelper';

interface Props {
    updateState: () => void;
    regime: string;
}

class ReportFieldInstructionEditModal extends React.Component<Props, ReportFieldInstructionEditModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            isOpen: false,
            saveUrl: '',
            instructions: {
                detailsToBeReported: '',
                detailsToBeReportedUrl: '',
                formatToBeReported: '',
                formatToBeReportedUrl: '',
                validations: '',
                validationEsmaTableRows: [[]],
                validationsUrl: '',
                cnPopulationGuidelines: '',
                cnPopulationGuidelinesUrl: '',
                rtsFieldNumber: '',
                t: '',
                p: ''
            },
            error: null
        };

        this.openListener = this.openListener.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
        this.close = this.close.bind(this);
        this.onChangeEsmaTableCell = this.onChangeEsmaTableCell.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.openListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    openListener(): void {
        let state = store.getState();

        if (state.action !== actions.reportFieldInstructionEditModal.open) {
            return;
        }

        this.setState(state.reportFieldInstructionEditModalState);
    }

    onChange(changes: ReportFieldInstructions): void {

        this.setState({ instructions: Object.assign({}, this.state.instructions, changes) });
    }

    onSaveClick(): Promise<any> {

        let error = validate(this.state.instructions);

        this.setState({ error: error });

        if (error) {
            return Promise.reject(error);
        }

        return httpClient.put(this.state.saveUrl, this.state.instructions).then(this.onSaveSuccess);
    }

    onSaveSuccess(): void {

        this.props.updateState();
        this.setState({ isOpen: false });

        store.dispatch({ type: actions.reportFieldInstructionEditModal.saveSuccess, payload: null as any });
    }

    close(): void {

        this.setState({ isOpen: false });
    }

    getSection(title: string, html: string, url: string, onHtmlChange: (value: string) => void, onUrlChange: (value: string) => void, additionalContent?: JSX.Element | null): JSX.Element {

        return (
            <>
                <div className="title-container">
                    <h5>{title}</h5>
                    <div>
                        <input type="text" placeholder="URL" value={url || ''} onChange={e => onUrlChange(e.currentTarget.value)} />
                    </div>
                </div>

                <div className="section">
                    <RichTextEditor html={html} update={onHtmlChange} />
                </div>
                {additionalContent}
            </>
        );
    }

    getOnlyValueSection(title: string, html: string, onHtmlChange: (value: string) => void): JSX.Element {
        return (
            <>
                <div className="title-container">
                    <h5>{title}</h5>
                </div>

                <div className="section">
                    <RichTextEditor html={html} update={onHtmlChange} />
                </div>
            </>
        );
    }

    onChangeEsmaTableCell(e: any, rowIndex: number, cellIndex: number): void {
        const value = e.currentTarget.value;
        let instructions = Object.assign({}, this.state.instructions);

        if (instructions.validationEsmaTableRows) {
            instructions.validationEsmaTableRows[rowIndex][cellIndex] = value;
        }

        this.setState({ instructions });
    }

    getDetailsToBeReportedString(regime: string): string {

        switch (regime) {
            case reportRegime.ASIC:
            case reportRegime['ASIC Rewrite']:
                return 'Details to be reported (ASIC)';
            default:
                return 'Details to be reported (ESMA)';
        }
    }

    getFormatToBeReportedString(regime: string): string {

        switch (regime) {
            case reportRegime.ASIC:
            case reportRegime['ASIC Rewrite']:
                return 'Format to be reported (ASIC)';
            default:
                return 'Format to be reported (ESMA)';
        }
    }

    getValidationsString(regime: string): string {

        switch (regime) {
            case reportRegime.ASIC:
            case reportRegime['ASIC Rewrite']:
                return 'Validations (ASIC)';
            default:
                return 'Validations (ESMA)';
        }
    }

    getFieldNumberString(regime: string): string {

        switch (regime) {
            case reportRegime.ASIC:
            case reportRegime['ASIC Rewrite']:
                return 'ASIC Field Number';
            default:
                return 'RTS Field Number';
        }
    }
  
    render(): JSX.Element {

        const validationEsmaTable = this.props.regime === reportRegime.REFIT
            && this.state.instructions.validationEsmaTableRows ?
            createValidationEsmaTable(this.state.instructions.validationEsmaTableRows, this.onChangeEsmaTableCell)
            : null;

        const detailsToBeReported = this.getDetailsToBeReportedString(this.props.regime);
        const formatToBeReported = this.getFormatToBeReportedString(this.props.regime);
        const validations = this.getValidationsString(this.props.regime);
        const fieldNumber = this.getFieldNumberString(this.props.regime);

        return (
            <Modal state={this.state} close={this.close} size="extra-large">

                <div className="report-field-instruction-edit-modal">

                    {this.getSection(
                        detailsToBeReported,
                        this.state.instructions.detailsToBeReported,
                        this.state.instructions.detailsToBeReportedUrl,
                        html => this.onChange({ detailsToBeReported: html } as ReportFieldInstructions),
                        url => this.onChange({ detailsToBeReportedUrl: url } as ReportFieldInstructions))}

                    {this.getSection(
                        formatToBeReported,
                        this.state.instructions.formatToBeReported,
                        this.state.instructions.formatToBeReportedUrl,
                        html => this.onChange({ formatToBeReported: html } as ReportFieldInstructions),
                        url => this.onChange({ formatToBeReportedUrl: url } as ReportFieldInstructions))}

                    {this.getSection(
                        validations,
                        this.state.instructions.validations,
                        this.state.instructions.validationsUrl,
                        html => this.onChange({ validations: html } as ReportFieldInstructions),
                        url => this.onChange({ validationsUrl: url } as ReportFieldInstructions),
                        validationEsmaTable)}

                    {this.getSection(
                        'CN Population Guidelines',
                        this.state.instructions.cnPopulationGuidelines,
                        this.state.instructions.cnPopulationGuidelinesUrl,
                        html => this.onChange({ cnPopulationGuidelines: html } as ReportFieldInstructions),
                        url => this.onChange({ cnPopulationGuidelinesUrl: url } as ReportFieldInstructions))}

                    {this.getOnlyValueSection(
                        fieldNumber,
                        this.state.instructions.rtsFieldNumber,
                        html => this.onChange({ rtsFieldNumber: html } as ReportFieldInstructions))}

                </div>

                <div className="mt-3">

                    <PromiseButton text="Save" className="btn cb-btn" enableOnErrorOnly={false} task={this.onSaveClick} />

                </div>

            </Modal>
        );
    }

}

export default ReportFieldInstructionEditModal;
